<script setup lang="ts">
import { computed, onMounted, onUnmounted, onUpdated, reactive, ref, watch } from 'vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import SeriesSlider from '@/components/Sliders/SeriesSlider.vue'
import Popper from 'vue3-popper'
import { useRoute } from 'vue-router'
import { usePlayerStore } from '@/stores/player'
import { likeUpdateById } from '@/api'
import { useShare } from '@vueuse/core'
import { isClient } from '@vueuse/shared'
import { API_MEDIA } from '@/helpers/globalVars'
import Hls from 'hls.js'
import { formatTime } from '@/helpers/'

const isOverLayHidden = ref(false)
const overLayCounter = ref(0)

const options = ref({
  title: 'YKVIK.COM',
  text: '',
  url: isClient ? location.href : ''
})
const openShare = () => {
  return share().catch(err => console.log('Share Error', err))
}
const likeUpdate = async () => {
  try {
    isLiked.value = await likeUpdateById(route.params.media as string)
  } catch (error) {
    console.log(error)
  }
}
const fullScreen = () => {
  const requestFullScreen = (element) => {
    const requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen
    if (requestMethod) {
      requestMethod.call(element)
    }
  }
  if (document.fullscreenElement) {
    document.exitFullscreen()
  } else {
    const wrapper = document.getElementsByClassName('player-layout')[0]
    requestFullScreen(wrapper)
  }
}
const parseTime = (time: number) => {
  const hasHours = (media.value.time.fullTime / 3600) >= 1.0
  return formatTime(time, hasHours)
}
const setTime = (e) => {
  showControls()
  try {
    const containerVideo = document.getElementById('containerVideo')
    const currentPosition = e.offsetX > 0 ? e.offsetX : 0
    const wrapperProgress = document.getElementById('wrapperProgress')
    if (currentPosition / wrapperProgress.offsetWidth <= 0.99) {
      containerVideo.currentTime = (currentPosition / wrapperProgress.offsetWidth) * media.value.time.fullTime
    }
  } catch (e) {
    console.log('Error', e)
  }
}
const volumeChange = () => {
  const containerVideo = document.getElementById('containerVideo')
  containerVideo.muted = !containerVideo.muted
}
const showControls = () => {
  isOverLayHidden.value = false
  overLayCounter.value = 0
}
const { share, isSupported } = useShare(options)
const route = useRoute()
const playerStore = usePlayerStore()

const media = computed(() => playerStore.info)
const likes = ref(0)
const isLiked = ref(null)

const hls = ref(new Hls())
watch(media, (value, oldValue) => {
  if (!value?.playlist_id) {
    return undefined
  }
  const containerVideo = document.getElementById('containerVideo')
  if (value.playlist_id !== oldValue.playlist_id) {
    hls.value.loadSource(`${API_MEDIA}${value.bitrate.list[value.bitrate.current]}`)
    hls.value.attachMedia(containerVideo)
    hls.value.on(Hls.Events.MEDIA_ATTACHED, function () {
      isLiked.value = null
      containerVideo.muted = value.status.isMuted
      containerVideo.addEventListener('canplaythrough', async () => {
        await containerVideo.play()
          .then(() => playerStore.setPlay(true))
          .catch(err => console.log('Error start auto play', err))
      })
      containerVideo.addEventListener('play', function () {
        playerStore.setPlay(true)
      })
      containerVideo.addEventListener('pause', function () {
        playerStore.setPlay(false)
      })
      containerVideo.addEventListener('timeupdate', () => {
        playerStore.setFullTime(containerVideo.duration)
        playerStore.setCurrentTime(containerVideo.currentTime)
        if (!isOverLayHidden.value) {
          try {
            const wrapperProgress = document.getElementById('wrapperProgress')
            const progressFullWidth = wrapperProgress.offsetWidth ?? 0
            const progress = Math.floor(media.value.time.current) / Math.floor(media.value.time.fullTime)
            playerStore.setProgress(Math.floor(progress * progressFullWidth))
          } catch (e) {
            console.log('Progress update error')
          }
          overLayCounter.value += 1
          if (overLayCounter.value >= 15) {
            isOverLayHidden.value = true
          }
        }
      })
      containerVideo.addEventListener('volumechange', function (e) {
        playerStore.toggleMute(containerVideo.muted)
      })
      containerVideo.addEventListener('error', function (e) {
        console.log('Error play', e)
        playerStore.setPlay(false)
      })
    })
  }
  if (value.status.isPlaying !== oldValue.status.isPlaying) {
    value.status.isPlaying ? containerVideo.play() : containerVideo.pause()
  }
  if (oldValue.bitrate.current && value.bitrate.current !== oldValue.bitrate.current) {
    const selectBitrate = value.bitrate.list[value.bitrate.current]
    const time = media.value.time.current
    hls.value.loadSource(`${API_MEDIA}${selectBitrate}`)
    containerVideo.currentTime = time
  }
})
watch(() => route.params.media, (newId) => {
  playerStore.$reset()
  overLayCounter.value = 0
  playerStore.fetchMedia(newId)
})
onUnmounted(() => {
  playerStore.$reset()
  overLayCounter.value = 0
})
playerStore.fetchMedia(route.params.media as string)
</script>

<template>
<div @click="showControls" class="player" :key="route.params.media">
  <video playsInline id="containerVideo" class="player-preview__background"/>
  <div class="player-actions" :class="[isOverLayHidden ? 'play' : 'info pause']">
    <transition name="fade">
      <div class="player-actions__series-wrapper full-width d-flex" v-show="!isOverLayHidden">
        <SeriesSlider v-if="media?.episodes_list" :seriesList="media.episodes_list"/>
      </div>
    </transition>
    <transition name="fade">
      <div class="player-actions__button pr-20 d-flex justify-center" v-if="!isOverLayHidden">
        <CircleButton v-if="media.status.isLoaded" @click="playerStore.toggleStateVideo" class="btn-player__action" :name="media?.status.isPlaying ? 'pause' : 'play'" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
      </div>
    </transition>
    <transition name="fade">
      <div class="player-actions__info" v-if="!isOverLayHidden">
        <div class="full-screen">
          <CircleButton v-if="media.status.isLoaded" @click="fullScreen" name="resize-full" :iconWidth="24" :iconHeight="24" view-box="0 0 24 24"/>
        </div>
        <h2 v-if="media.status.isLoaded">{{media?.title}} - {{media?.episode_num}} серия</h2>
        <p v-if="media.status.isLoaded" class="category">{{media?.episode_spec}}</p>
        <div v-if="media.status.isLoaded" class="controls mb-20">
          <div class="control-left">
            <CircleButton @click="likeUpdate" :name="!isLiked ? 'like' : 'saved-like'" :iconWidth="18" :iconHeight="15.96" view-box="0 0 18 15.96">{{likes && !isLiked ? '' : isLiked}}</CircleButton>
          </div>
          <div v-if="media.status.isLoaded" class="control-right">
            <CircleButton @click="volumeChange" :name="media?.status.isMuted ? 'mute' : 'volume'" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
            <CircleButton v-if="isSupported" @click="openShare" name="upload" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
            <div class="btn_group p-relative">
              <Popper :interactive="true" class="popper">
                <CircleButton name="empty">{{media?.bitrate.current}}p</CircleButton>
                <template #content>
                  <CircleButton class="mb-10" :bg="bitrate == media?.bitrate.current ? 'gradient2':'border'" v-for="(encode, bitrate) in media?.bitrate.list" @click="playerStore.setBitrate(parseInt(bitrate))" :key="encode" popovertarget="encodes_list" name="empty">{{bitrate}}p</CircleButton>
                </template>
              </Popper>
            </div>
            <router-link v-if="media?.playlist_id && media?.episode_num" :to="{name: 'project', params: {id: media?.playlist_id}, query: {series: media?.episode_num}}">
              <CircleButton name="chevron-down" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
            </router-link>
          </div>
        </div>
        <div v-if="media.status.isLoaded" class="progress mb-20">
          <div class="progress-time__current">{{parseTime(media?.time.current)}}</div>
          <div draggable="true" @drag="setTime" @dragend="setTime" @click="setTime" id="wrapperProgress" class="progress-bar">
            <span :style="{ width: `${media?.progress.width}px` }" class="progress-bar__current" />
          </div>
          <div class="progress-time__ended">{{parseTime(media?.time.fullTime)}}</div>
        </div>
      </div>
    </transition>
  </div>
</div>
</template>

<style scoped lang="less">
:root {
  --scrollbarBgColor: rgba(0, 0, 0, .05);
  --scrollbarThumbColor: rgba(0, 0, 0, .15);
  --scrollbarThumbColorSecond: rgba(255, 255, 255, .7);
  --scrollbarWidth: 16px;
  --scrollbarBorder: 0 solid var(--scrollbarBgColor);
  --scrollbarBorderRadius: calc(var(--scrollbarWidth) / 4);
}
#app {
  display: flex;
  margin: 0 auto;
}
.player {
  position: relative;
  width: auto;
  display: inline-block;
  margin: 0 auto;
  &-preview {
    &__background {
      height: 100vh;
      transition: opacity 0.5s ease;
      max-width: 100%;
    }
  }
  &-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    align-items: center;
    //height: 100vh;
    transition: opacity 0.5s ease;
    top: 0;
    bottom: 0;
    width: 100%;
    &.info {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.13%, rgba(0, 0, 0, 0.7) 100%);
      &.pause {
        background: rgba(0, 0, 0, 0.6);
      }
    }
    &__series {
      &-wrapper {
        position: absolute;
        top: 20px;
        left: 10px;
        width: 100%;
        padding-right: 20px;
        padding-bottom: 5px;
      }
    }
    &__info {
      width: 100%;
      padding: 0 10px;
      .full-screen {
        position: absolute;
        top: 80px;
        right: 5px;
      }
      .category {
        font-family: Wix Madefor Display,serif;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        text-transform: uppercase;
      }
      .controls {
        display: flex;
        justify-content: space-between;
        .control {
          &-left {
            .btn {
              margin-right: 10px;
            }
          }
          &-right {
            display: flex;
            & > .btn {
              margin-right: 10px;
              &_group {
                margin-right: 10px;
              }
              .popper {
                display: flex;
                flex-direction: column;
                align-items: center;
                .btn {
                  text-align: center;
                }
              }
            }
            .btn:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .progress {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        font-family: Wix Madefor Display,serif;
        font-size: 8px;
        font-weight: 600;
        line-height: 14px;
        color: #ffffff;
        &-bar {
          width: 100%;
          height: 4px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          position: relative;
          cursor: pointer;
          &__current {
            display: inline-flex;
            height: 4px;
            background: linear-gradient(90deg, #9FE870 0%, #3FD9FB 100%);
            position: absolute;
          }
        }
        &-time {
          &__current {
            margin-right: 10px;
          }
          &__ended {
            margin-left: 10px;
          }
        }
      }
    }
    &__button {
      height: 100%;
      align-items: center;
      .btn {
        background: rgba(217, 217, 217, 0.1);
        border: 2px solid rgba(217, 217, 217, 0.1);
      }
    }
  }
}
fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
@supports (-webkit-touch-callout: none) {
  .full-screen {
    display: none;
  }
}

@media (max-width:482px){
  .player {
    height: fit-content;
    &-preview {
      &__background {
        height: auto;
      }
    }
  }
}

//video::-webkit-media-controls-fullscreen-button {
//  display: none;
//}
</style>
