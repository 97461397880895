<script setup lang="ts">
import IconBase from '@/components/Icons/IconBase.vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useAuthStore } from '@/stores/auth'

const router = useRouter()

const authStore = useAuthStore()
const isAuth = computed(() => authStore.isAuth)

</script>

<template>
<div class="header-content">
  <router-link to="/" class="header-content__logo">
    <IconBase name="logo" :width="80" :height="15" view-box="0 0 80 15" />
  </router-link>
  <div class="header-actions">
    <router-link to="/"><CircleButton bg="theme" name="home" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/></router-link>
    <router-link :to="{name: 'profile'}">
      <CircleButton :bg="isAuth ? 'gradient' : 'theme'" name="profile" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
    </router-link>
  </div>
</div>
</template>

<style scoped lang="less">
.header {
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 10px;
    background: var(--primary-color);
    &__logo {
      color: var(--app-logo-color);
      display: flex;
    }
  }
  &-actions {
    .btn, a {
      width: 34px;
      height: 34px;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
</style>
