import { defineStore } from 'pinia'
import { getMediaById, IMedia } from '@/api'

export const usePlayerStore = defineStore('player-store', {
  state: () => ({
    isPlaying: false,
    isLoading: false,
    isMuted: false,
    mediaUrl: '',
    currentTime: 0,
    fullTime: 0,
    media: <IMedia | null>null,
    bitrateList: {},
    currentBitrate: <number | null>null,
    progressWidth: 0,
    isOverlay: true
  }),

  getters: {
    info: (state: any) => {
      return {
        ...state.media?.description,
        status: {
          isLoaded: !state.isLoading,
          isPlaying: state.isPlaying,
          isMuted: state.isMuted,
          isOverlay: state.isOverlay
        },
        bitrate: {
          list: state.bitrateList,
          current: state.currentBitrate
        },
        time: {
          current: state.currentTime,
          fullTime: state.fullTime
        },
        progress: {
          width: state.progressWidth
        }
      }
    }
  },
  actions: {
    async fetchMedia (mediaId: string) {
      if (this.$state.isLoading) {
        return undefined
      }
      this.setLoading(true)
      console.log('Get Media ById', mediaId)
      try {
        const { data } = await getMediaById(mediaId)
        const description = data.description.toString().replace(/\\n/g, '<br>')
        this.$state.media = { ...data, ...{ description: JSON.parse(description) } }
        Object.keys(this.$state.media?.hls_info).map((key, value) => {
          const bitrate = parseInt(key)
          const url = this.$state.media?.hls_info[key].toString()
          if (!isNaN(parseInt(key)) && url.includes('stream')) {
            if (!this.$state.currentBitrate && bitrate === 480) {
              this.$state.currentBitrate = bitrate
            }
            this.$state.bitrateList = { ...this.$state.bitrateList, ...{ [bitrate]: url } }
          }
          return value
        })
        this.setLoading(false)
      } catch (error) {
        console.error(error, 'error')
        this.setLoading(false)
      }
    },
    setLoading (value: boolean) {
      this.$state.isLoading = value
    },
    setBitrate (value: number) {
      this.$state.currentBitrate = value
    },
    toggleMute (value: boolean) {
      this.$state.isMuted = value
    },
    toggleStateVideo () {
      this.$state.isPlaying = !this.$state.isPlaying
    },
    setCurrentTime (time: number) {
      this.$state.currentTime = time
    },
    setFullTime (time: number) {
      this.$state.fullTime = time
    },
    setPlay (value: boolean) {
      this.$state.isPlaying = value
    },
    setProgress (value: number) {
      this.$state.progressWidth = value
    },
    setOverlay (value: boolean) {
      this.$state.isOverlay = value
    }
  }
})
