<script setup lang="ts">

import router from '@/router'
import BackLine from '@/components/Lines/BackLine.vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import CreateAccount2 from '@/components/Register/CreateAccount2.vue'
import ProjectSeriesSlider from '@/components/Sliders/ProjectSeriesSlider.vue'

import BorderText from '@/components/BorderText.vue'
import BackStagePlayer from '@/components/Players/BackStagePlayer.vue'
import { computed, onMounted, ref, unref } from 'vue'
import { useRoute } from 'vue-router'
import { getPlaylistById, IPlaylist } from '@/api'
import PreviewTrailerPlayer from '@/components/Players/PreviewTrailerPlayer.vue'
import { useAuthStore } from '@/stores/auth'
import NewProjectIdea from '@/components/NewProjectIdea.vue'
import ProjectVotes from '@/components/ProjectVotes.vue'

const authStore = useAuthStore()
const isAuth = computed(() => authStore.isAuth)

const route = useRoute()
const playList = ref<IPlaylist | null>(null)

const loadPlaylistDataAsync = async (playListId) => {
  if (!playListId) {
    return undefined
  }
  try {
    const { data } = await getPlaylistById(playListId)
    playList.value = data
    const description = playList.value?.description.toString().replace(/\\n/g, '<br>')
    try {
      playList.value.description = JSON.parse(description)
    } catch (e) {
      console.log('JSON parse error')
    }
  } catch (error) {
    console.error(error, 'error')
  }
}

loadPlaylistDataAsync(route.params.id)

</script>

<template>
  <BackLine>
    <CircleButton @click="router.go(-1)" name="chevron-left-gradient" bg="empty" :iconWidth="21" :iconHeight="16" view-box="0 0 21 16"/>
  </BackLine>
  <ProjectSeriesSlider v-if="playList?.playlist_media" :playlistMedia="playList?.playlist_media" />
  <BorderText class="pa-10">
    <h2 class="text-gradient weight-500 d-inline-flex">{{playList?.description?.title}}</h2>
    <h6 class="fs-14" v-html="playList?.description?.description" />
  </BorderText>
  <BorderText class="pa-10">
    <h2 class="mb-20 weight-500">В ролях:</h2>
    <span class="role pa-10" :key="role" v-for="role in playList?.description?.roles">{{role}}</span>
  </BorderText>
  <div v-if="playList?.description" class="trailer px-20 py-10 mt-20">
    <h2>Трейлер</h2>
    <PreviewTrailerPlayer v-if="playList?.description" :trailerId="playList?.description.trailer" />
  </div>
  <BorderText class="pa-10">
    <NewProjectIdea v-if="playList?.description" :title="playList?.description?.title" />
  </BorderText>
  <ProjectVotes />
  <div v-if="playList?.description" class="backstage my-40 px-20">
    <h2 class="mb-20">Бекстейдж</h2>
    <BackStagePlayer v-if="playList?.description" :trailerId="playList?.description.backst_id" :backstPict="playList?.description.backst_pict"/>
  </div>
  <CreateAccount2 v-if="!isAuth" />
</template>

<style scoped lang="less">
.role {
  display: inline-block;
  color: #ffffff;
  background: rgb(25,25,25);
  border-radius: 10px;
  margin-right: 10px;
  font-family: Wix Madefor Display, serif;
  margin-bottom: 10px;
}
p {
  font-family: Wix Madefor Display, serif;
  font-size: 14px;
}
</style>
