<script setup lang="ts">
import { onMounted, ref, defineProps } from 'vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import { getMediaById } from '@/api'
import Hls from 'hls.js'

const props = defineProps<{
  trailerId: string | undefined
}>()

const hls = ref()
const containerVideo = ref<HTMLVideoElement | null>(null)
const isPlaying = ref(false)
const trailer = ref()
const isOverLayHidden = ref(false)
const overlayTimer = ref()
const isShowPreviewImage = ref(true)
const volume = ref(1)
const loadTrailerDataAsync = async () => {
  try {
    const { data } = await getMediaById(props.trailerId)
    trailer.value = data
    if (Hls.isSupported()) {
      hls.value = new Hls()
    }
    hls.value.loadSource(trailer.value.hls_info.master_file)
    hls.value.attachMedia(document.getElementById('containerVideo'))
    hls.value.on(Hls.Events.MEDIA_ATTACHED, function () {
      containerVideo.value.volume = volume.value
      containerVideo.value.addEventListener('play', (e) => {
        isShowPreviewImage.value = false
        isPlaying.value = true
        setTimeout(() => {
          isOverLayHidden.value = true
        }, 1000)
      })
      containerVideo.value.addEventListener('pause', () => {
        isPlaying.value = false
        if (overlayTimer.value) {
          clearTimeout(overlayTimer.value)
        }
        isOverLayHidden.value = false
      })
    })
  } catch (e) {
    console.log('Get media error', e)
  }
}
const overlayHandler = () => {
  isOverLayHidden.value = false
  const time = setTimeout(() => {
    if (isPlaying.value) {
      clearTimeout(time)
      isOverLayHidden.value = true
    }
  }, 3000)
}
const toggleVolume = () => {
  volume.value = Number(!volume.value)
  containerVideo.value.volume = volume.value
}
if (props.trailerId) {
  loadTrailerDataAsync()
}
</script>

<template>
  <div @click="overlayHandler" class="player-preview">
    <div v-if="!isOverLayHidden" :class="!isPlaying ? 'player-preview__overlay player-preview__overlay-black':'player-preview__overlay'">
      <CircleButton @click="containerVideo.play()" v-if="!isPlaying" class="btn-player__action play" bg="none" name="play" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
      <CircleButton @click="containerVideo.pause()" v-else class="btn-player__action play" bg="none" name="pause" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
      <div class="controls mb-20">
        <div class="control-right">
          <CircleButton @click="toggleVolume" :name="volume ? 'volume':'mute'" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
        </div>
      </div>
    </div>
    <div v-if="trailer && isShowPreviewImage" class="preview-image">
      <img :src="trailer?.poster_url" alt="">
    </div>
    <video playsInline ref="containerVideo" id="containerVideo" class="player-preview__background"/>
  </div>
</template>

<style scoped lang="less">
.player-preview {
  position: relative;
  &__background {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    &-black {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 20px;
    }
    .bg-none {
      opacity: 1;
      color: #ffffff;
      border: #ffffff solid 2px;
    }
    .controls {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      .control-right {
        position: absolute;
        right: 15px;
        bottom: 0;
      }
    }
  }
  .preview-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
}

@media (min-width:491px){
  .player-preview {
    width: 450px;
    margin: 0 auto;
  }
}
</style>
