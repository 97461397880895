<script setup lang="ts">

import IconBase from '@/components/Icons/IconBase.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import { useAuthStore } from '@/stores/auth'
import { computed, defineProps, ref } from 'vue'
import { IValidateForm, Validate } from '@/helpers/validate'
import axios from 'axios'
import { API_URL } from '@/helpers/globalVars'

const props = defineProps<{
  title: string
}>()

const authStore = useAuthStore()
const isAuth = computed(() => authStore.isAuth)

const currentField = ref<string>('')
const errors = ref<any>({})
const subject = ref<string>('')
const isSend = ref<boolean>(false)

const inFocus = (input: string) => {
  currentField.value = input
  delete errors.value[input]
}
const outFocus = (
  input: string,
  // ESLINT-ERROR: Старая реализация
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any,
  title: string,
  rules: string[]
) => {
  currentField.value = ''
  errors.value = Validate([
    {
      name: input,
      rules: rules as IValidateForm['rules'],
      value: model,
      ru: title
    }
  ])
}
const onSubmit = async () => {
  if (!isAuth.value) {
    return undefined
  }
  const validate = Validate([
    {
      name: 'subject',
      rules: ['required'],
      value: subject.value,
      ru: 'идея'
    }
  ])
  errors.value = {}
  if (Object.keys(validate).length) {
    errors.value = validate
  } else {
    await axios.post(`${API_URL}send_email/`, {
      sender_email: 'reg@ykvik.com',
      receiver_email: 'idea@ykvik.com',
      subject: 'ПРЕДЛОЖЕНИЯ ОТ ПОЛЬЗОВАТЕЛЯ',
      message: `${props.title}, ${subject.value}`
    }).catch((e) => {
      console.log('Error Request Idea', e)
      isSend.value = true
    }).finally(() => {
      isSend.value = true
    })
  }
}
</script>

<template>
  <h3 class="mb-20">Предложи свою идею...</h3>
  <h6 v-if="!isSend" class="mb-10">Стань соавтором новой серии и предложи  идею для съемок!</h6>
  <form v-if="!isSend" class="create-idea" @submit.prevent="onSubmit">
    <BaseInput
      @focusin="inFocus('subject')"
      @focusout="
          outFocus('subject', subject, 'Идея', ['required'])
        "
      v-model="subject"
      type="text"
      class="full-width"
      placeholder="Напиши свою идею здесь..."
    />
    <BaseButton class="mt-10 large rounded full-width d-flex justify-between" bg="gradient" border="gradient">Отправить <icon-base v-if="!isAuth" name="lock" view-box="0 0 12 15" :width="12" :height="15"/></BaseButton>
  </form>
  <p v-if="!isAuth && !isSend" class="mt-10 text-center text-gradient">только для зарегистрированных пользователей</p>
  <p v-if="isSend" class="mt-10 text-center text-gradient send-success">Спасибо! Идея принята!</p>
</template>

<style scoped lang="less">
p {
  font-family: Wix Madefor Display, serif;
  font-size: 14px;
  &.send-success {
    font-size: 16px;
  }
}
</style>
