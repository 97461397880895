import { defineStore } from 'pinia'
import { getCatalog, searchMedia } from '@/api'

export const useCatalogStore = defineStore('catalog-store', {
  state: () => ({
    items: [],
    isLoaded: false
  }),
  getters: {
    catalog: (state: any) => {
      return state.catalog
    }
  },
  actions: {
    async fetchCatalogAsync () {
      try {
        const { data } = await getCatalog()
        data.results.map(async (item: any) => {
          item.url = item.url.replace('/playlists/', '')
          try {
            const description = item.description.toString().replace(/\\n/g, '<br>')
            item.description = JSON.parse(description)
            item.thumbnail = item.description?.thumbnail_url
          } catch (e) {
            console.log('JSON parse error')
          }
        })
        data.results.sort((a: any, b: any) => parseInt(a.description.order) - parseInt(b.description.order))
        this.$state.items = data
        this.$state.isLoaded = true
      } catch (errorCatch) {
        console.log(errorCatch)
      }
    }
  }
})
