<script setup lang="ts">
import HomeCatalogItem from '@/components/Catalogs/HomeCatalogItem.vue'
import IconBase from '@/components/Icons/IconBase.vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import { defineProps, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useCatalogStore } from '@/stores/catalog'

const count = ref(false)
const showMore = () => {
  count.value = true
}
const hideMore = () => {
  count.value = false
}
const props = defineProps(['catalog'])
onMounted(() => {
  Object.keys(props.catalog.results).map((index) => {
    return props.catalog.results[index]
  })
})
</script>

<template>
  <div class="catalog">
    <h2>Каталог</h2>
    <div class="catalog-items">
      <HomeCatalogItem v-for="(item, index) in props.catalog?.results" :key="`slide_${index}`" :item="item" v-show="index < 2"/>
    </div>
    <Transition>
    <div class="catalog-items" v-show="count">
        <HomeCatalogItem v-for="(item, index) in props.catalog?.results" :key="`slide_${index}`" :item="item" v-show="index >= 2" />
    </div>
    </Transition>
    <BaseButton v-if="!count" @click="showMore" class="large rounded more-btn" bg="gradient" border="gradient"><span>Показать ещё</span> <icon-base name="chevron-down" view-box="0 0 10 4" :width="20" :height="8"/></BaseButton>
    <BaseButton v-else @click="hideMore" class="large rounded more-btn" bg="gradient" border="gradient"><span>Свернуть</span> <icon-base name="chevron-down" view-box="0 0 10 4" :width="20" :height="8"/></BaseButton>
  </div>
</template>

<style scoped lang="less">
.catalog {
  padding: 20px 10px;
  &-items {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  .more-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.v-enter-active {
  transition: opacity 0.5s ease;
}

.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
